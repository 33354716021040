import { getSessionWholeMinutes } from '../../utils/functions';

const SessionTimelineScaleLabels = ({ session }) => {
  const wholeMinutes = getSessionWholeMinutes(session);

  const getLabelStyle = (index) => {
    return {
      left: `calc(${index * 100/wholeMinutes}%)`,
      display: (wholeMinutes > 20 && index % 5 === 0) || wholeMinutes <= 20 ? 'block' : 'none',
      paddingLeft: '0.1rem',
    };
  };

  return (
    <div className={'position-sticky top-0'}>
      <div className={'position-relative w-75'} style={{ marginLeft: '25%'}}>
        {[...Array(wholeMinutes).keys()].map(index => (
          <div key={index} style={getLabelStyle(index)} className={'small text-muted position-absolute'}>
            {index}m
          </div>
        ))}
      </div>
    </div>
  );
}

export default SessionTimelineScaleLabels;
