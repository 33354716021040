import { useState } from 'react';
import { Button } from 'react-bootstrap';
import SessionEventTypeModal from '../SessionEventTypeModal';
import SessionRoutine from '../SessionRoutine';


const SessionRoutineTimeline = ({
  eventType,
  session,
}) => {
  const [showSessionEventTypeModal, setShowSessionEventTypeModal] = useState(false);
  const labelStyle = {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '100%',
  };

  return (
    <div className={'session-chart-row'}>
      <div className={'w-25 d-inline-block'}>
        <Button
          variant={'link'}
          size={'sm'}
          className={'text-decoration-none p-0 ps-2'}
          style={labelStyle}
          onClick={() => setShowSessionEventTypeModal(true)}
        >
          {eventType.label}
        </Button>
      </div>
      <div className={'position-relative w-75 d-inline-block'}>
        {session.events.filter(e => e.key === eventType.key).map((event, index) => (
          <SessionRoutine key={index} {...{ session, event }} />
        ))}
      </div>
      {showSessionEventTypeModal && (
        <SessionEventTypeModal {...{ eventType, setShowSessionEventTypeModal }} />
      )}
    </div>
  );
}

export default SessionRoutineTimeline;
