import { useState } from 'react';
import axios from 'axios';
import {
  Alert,
  Button,
  Form,
} from 'react-bootstrap';

const ChangePasswordForm = () => {
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordConfirmation, setNewPasswordConfirmation] = useState('');
  const [errors, setErrors] = useState(null);
  const [success, setSuccess] = useState(false);

  const handleCurrentPasswordChange = (e) => {
    setCurrentPassword(e.target.value);
  }

  const handleNewPasswordChange = (e) => {
    setNewPassword(e.target.value);
  }

  const handleNewPasswordConfirmationChange = (e) => {
    setNewPasswordConfirmation(e.target.value);
  }

  const onSubmit = (e) => {
    e.preventDefault();
    const url = `${process.env.REACT_APP_API_BASE_URL}/api/account/password`;
    setErrors(null);
    axios.put(url, {
        current_password: currentPassword,
        password: newPassword,
        password_confirmation: newPasswordConfirmation,
      })
      .then(response => {
        setSuccess(true);
      })
      .catch(error => {
        if (error.response) {
          const { response } = error;
          const { data, status } = response;
          if (status === 422) {
            const errorsArray = [];
            Object.keys(data.errors).forEach(key => {
              data.errors[key].forEach(message => {
                errorsArray.push({ key, message });
              });
            });
            setErrors(errorsArray);
          } else {
            setErrors([{message: 'A System Error occurred'}]);
          }
        } else {
          console.log(error);
          setErrors([{message: 'A System Error occurred'}]);
        }
      });
  }

  if (success) {
    return (
      <Alert variant={'success'}>Your password has been changed!</Alert>
    );
  }

  return (
    <Form onSubmit={onSubmit}>
      {errors && errors.map(error => (
        <Alert variant={'danger'}>{error.message}</Alert>
      ))}
      <Form.Group className={'mb-4'}>
        <Form.Label>Current Password</Form.Label>
        <Form.Control type={'password'} placeholder={'Current Password'} value={currentPassword} onChange={handleCurrentPasswordChange} />
      </Form.Group>
      <Form.Group className={'mb-4'}>
        <Form.Label>New Password</Form.Label>
        <Form.Control type={'password'} placeholder={'Current Password'} value={newPassword} onChange={handleNewPasswordChange} />
      </Form.Group>
      <Form.Group className={'mb-4'}>
        <Form.Label>Confirm New Password</Form.Label>
        <Form.Control type={'password'} placeholder={'Confirm New Password'} value={newPasswordConfirmation} onChange={handleNewPasswordConfirmationChange} />
      </Form.Group>
      <div className={'d-grid gap-2'}>
        <Button type={'submit'}>Submit</Button>
      </div>
    </Form>
  );
}

export default ChangePasswordForm;
