import { Col, Form, ListGroup, Row } from 'react-bootstrap';

const AdminUsersIndexHeaderRow = ({ emailDomains, selectedEmailDomain, setSelectedEmailDomain }) => {

  const onChangeEmailDomain = (e) => {
    setSelectedEmailDomain(e.target.value ? e.target.value : null);
  }

  return (
    <ListGroup.Item>
      <Row className={'fw-bold'}>
        <Col sm={3}>Name</Col>
        <Col sm={4} className={'position-relative'}>
          Email
          { emailDomains?.length && (
            <Form.Select
              size={'sm'}
              value={selectedEmailDomain}
              onChange={onChangeEmailDomain}
              style={{ width: 'unset', top: '-.25rem' }}
              className={'d-inline-block ms-3 position-absolute'}
            >
              <option value={''}>All Email Domains</option>
              {emailDomains.map(emailDomain => (
                <option value={emailDomain}>{ emailDomain }</option>
              ))}
            </Form.Select>
          )}
        </Col>
        <Col sm={1} className={'text-end'}>Sessions</Col>
        <Col sm={3} className={'text-end'}>Created On</Col>
        <Col sm={1} />
      </Row>
    </ListGroup.Item>
  );
}

export default AdminUsersIndexHeaderRow;
