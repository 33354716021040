import { useState } from 'react';
import { Alert, Button, Form, Modal } from 'react-bootstrap';
import axios from 'axios';

const ForgotPasswordModal = ({ setShowForgotPasswordModal }) => {
  const [email, setEmail] = useState('');
  const [errors, setErrors] = useState(null);
  const [success, setSuccess] = useState(false);

  const onChangeEmail = (e) => {
    setEmail(e.target.value);
  }

  const onSubmit = (e) => {
    e.preventDefault();
    setErrors(null);
    axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/password-resets`, { email })
      .then(response => {
        setSuccess(true);
      })
      .catch(error => {
        if (error.response) {
          const { response } = error;
          const { data, status } = response;
          if (status === 422) {
            const errorsArray = [];
            Object.keys(data.errors).forEach(key => {
              data.errors[key].forEach(message => {
                errorsArray.push({ key, message });
              });
            });
            setErrors(errorsArray);
          } else {
            setErrors([{ key: null, message: 'A System Error occurred'}]);
          }
        } else {
          setErrors([{ key: null, message: 'A System Error occurred'}]);
        }
        console.log(error);
      });
  }

  const isFieldInvalid = (fieldName) => {
    return errors && errors.map(error => error.key).includes(fieldName);
  }

  return (
    <Modal show onHide={() => setShowForgotPasswordModal(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Forgot Password</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {success ? (
          <>
            <Alert variant={'success'}>
              If we found an account with the email <em>{email}</em> you'll receive instructions there to reset your password.
            </Alert>
            <Button variant={'light'} className={'float-end'} onClick={() => setShowForgotPasswordModal(false)}>Close</Button>
          </>
        ) : (
          <Form onSubmit={onSubmit}>
            {errors && errors.map(error => (
              <Alert variant={'danger'} key={error.key+error.message}>{error.message}</Alert>
            ))}
            <Form.Group>
              <Form.Label
                className={ isFieldInvalid('email') ? 'text-danger' : '' }
              >
                Email
              </Form.Label>
              <Form.Control
                type={'email'}
                onChange={onChangeEmail}
                value={email}
                className={ isFieldInvalid('email') ? 'is-invalid' : '' }
                required
              />
            </Form.Group>
            <Form.Group className={'float-end mt-4'}>
              <Button onClick={() => setShowForgotPasswordModal(false)} variant={'light'}>Cancel</Button>
              <Button variant={'primary'} className={'ms-3'} type={'submit'}>Submit</Button>
            </Form.Group>
          </Form>
        )}
      </Modal.Body>
    </Modal>
  );
}

export default ForgotPasswordModal;
