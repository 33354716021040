import { useState } from 'react';
import SessionAnnotationModal from '../SessionAnnotationModal';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { CardImage, ChatLeftTextFill } from 'react-bootstrap-icons';
import {
  getOffsetLeft,
  formatTimeFromSessionStart
} from '../../utils/functions';


const SessionAnnotation = ({
  session,
  annotation,
}) => {
  const iconStyle = {
    position: 'absolute',
    left: `${getOffsetLeft(session, annotation)}%`,
    bottom: '-4px',
  };
  const [showSessionAnnotationModal, setShowSessionAnnotationModal] = useState(false);

  const renderTooltip = (props) => (
    <Tooltip {...props}>
      {formatTimeFromSessionStart(session, annotation.started_at)}
    </Tooltip>
  );

  return (
    <>
      <OverlayTrigger
        placement={'top'}
        overlay={renderTooltip}
      >
        <Button
          style={iconStyle}
          variant={'link'}
          className={'text-decoration-none p-0'}
          onClick={setShowSessionAnnotationModal}
        >
          {annotation.filename
            ? annotation.notes
              ? <><ChatLeftTextFill className={'mt-1'} /><CardImage /></>
              : <CardImage />
            : <ChatLeftTextFill className={'mt-1'} />
          }
        </Button>
      </OverlayTrigger>
      {showSessionAnnotationModal && (
        <SessionAnnotationModal {...{annotation, session, setShowSessionAnnotationModal}} />
      )}
    </>
  );
}

export default SessionAnnotation;
