import { Navigate, useOutletContext } from 'react-router-dom';
import useAuthCheck from '../../hooks/useAuthCheck';

const UserRoute = ({ children }) => {
  useAuthCheck();
  const { auth } = useOutletContext();

  if (!auth) {
    return <Navigate to={'/?sessionExpired=true'} />
  }

  return children;
}

export default UserRoute;
