import { Buffer } from 'buffer';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { Modal } from 'react-bootstrap';
import { formatTimeFromSessionStart } from '../../utils/functions';


const SessionAnnotationModal = ({ annotation, session, setShowSessionAnnotationModal }) => {
  const [imageSrc, setImageSrc] = useState(null);

  useEffect(() => {
    if (annotation.filename) {
      axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/api/sessions/${session.id}/images/${annotation.filename}`,
        {responseType: "arraybuffer",}
      ).then(response => {
        setImageSrc(`data:${
            response.headers["content-type"]
          };base64,${new Buffer(response.data, "binary").toString("base64")}`
        );
      });
    } else {
      setImageSrc(null);
    }
  }, []);

  return (
    <Modal size={'lg'} show onHide={() => setShowSessionAnnotationModal(false)}>
      <Modal.Header closeButton>
        <Modal.Title>
          {annotation.filename
            ? annotation.notes ? 'Image and Notes from ' : 'Image from '
            : 'Notes from '
          }
          {formatTimeFromSessionStart(session, annotation.started_at)}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {imageSrc && (
          <img src={imageSrc} className={'img-fluid'} alt={'snapshot or screenshot from the session'} />
        )}
        <p className={'my-2'}>{annotation.notes}</p>
      </Modal.Body>
    </Modal>
  );
}

export default SessionAnnotationModal;
