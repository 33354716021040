import { Form } from 'react-bootstrap';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';


const SelectedEventTypeForm = ({
  description,
  setDescription,
  editMode,
  fieldName,
  isFieldInvalid,
  label,
  setLabel,
}) => {
  const toolbarOptions = [
    { 'header': [1, 2, 3, false] },
    'bold',
    'italic',
    'underline',
    { 'list': 'bullet' },
    { 'list': 'ordered' },
  ];

  const onChangeLabel = (e) => {
    setLabel(e.target.value)
  }

  return editMode ? (
      <>
        <Form.Group>
          <Form.Label className={ isFieldInvalid('label') ? 'text-danger' : '' }>
            Label
          </Form.Label>
          <Form.Control
            value={label}
            onChange={onChangeLabel}
            className={ isFieldInvalid('label') ? 'is-invalid' : '' }
          />
        </Form.Group>
        <Form.Group className={'mt-3'}>
          <Form.Label className={ isFieldInvalid(fieldName) ? 'text-danger' : '' }>
            Practitioner Description
          </Form.Label>
          <ReactQuill
            modules={{toolbar: toolbarOptions}}
            value={description}
            onChange={setDescription}
            className={ isFieldInvalid(fieldName) ? 'border-danger' : '' }
          />
        </Form.Group>
      </>
    ) : (
      <div dangerouslySetInnerHTML={{ __html: description }} />
    );
}

export default SelectedEventTypeForm;
