import { getSessionWholeMinutes } from '../../utils/functions';

const SessionTimelineScale = ({ session }) => {
  const wholeMinutes = getSessionWholeMinutes(session);

  const getTickStyle = (index) => {
    return {
      width: `${100/wholeMinutes}%`,
      height: '100%',
      borderLeft: index % 5 !== 0 ? 'solid 1px #eee' : 'solid 1px #ccc',
    };
  }

  return (
    <div className={'position-absolute w-100 h-100'} style={{ zIndex: -1 }}>
      <div className={'h-100 w-75'} style={{ marginLeft: '25%'}}>
        {[...Array(wholeMinutes).keys()].map(index => (
          <div key={index} style={getTickStyle(index)} className={'small text-muted float-start d-inline-block'} />
        ))}
      </div>
    </div>
  );
}

export default SessionTimelineScale;
