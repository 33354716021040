import { Link, useOutletContext } from 'react-router-dom';
import {
  USER_TYPE_ADMIN,
  USER_TYPE_MEMBER,
  USER_STATUS_DISABLED,
} from '../../utils/constants';
import { formatDate } from '../../utils/functions';
import { Button, Col, ListGroup, Row } from 'react-bootstrap';
import { PencilFill } from 'react-bootstrap-icons';

const AdminUsersIndexUserRow = ({ user, setManagedUser }) => {
  const { auth } = useOutletContext();

  return (
    <ListGroup.Item>
      <Row className={user.status === USER_STATUS_DISABLED && 'opacity-50'}>
        <Col sm={3}>
          {user.id === auth.user.id ? (
            <>{user.name} <span className={'text-secondary'}>(you)</span></>
          ) : (
            <Link to={`/admin/users/${user.id}`}>{user.name}</Link>
          )}
          {user.status === USER_STATUS_DISABLED && ' (disabled)'}
        </Col>
        <Col sm={4}>
          {user.email}
        </Col>
        <Col sm={1} className={'text-end'}>
          {user.sessions_count}
        </Col>
        <Col sm={3} className={'text-end'}>
          {formatDate(user.created_at)}
        </Col>
        <Col sm={1} className={'text-end'}>
          {user.id !== auth.user.id && (
            <Button
              size={'sm'}
              variant={'link'}
              className={'text-decoration-none text-warning position-relative p-0'}
              style={{ top: -4 }}
              onClick={() => setManagedUser({
                ...user,
                type: user.roles.includes(USER_TYPE_ADMIN) ? USER_TYPE_ADMIN : USER_TYPE_MEMBER
              })}
            >
              <PencilFill />
            </Button>
          )}
        </Col>
      </Row>
    </ListGroup.Item>
  );
}

export default AdminUsersIndexUserRow;
