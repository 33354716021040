import { useState } from 'react';
import { Alert, Button, Form, Modal } from 'react-bootstrap';
import {
  USER_TYPE_ADMIN,
  USER_TYPE_MEMBER,
  USER_STATUS_ENABLED,
  USER_STATUS_DISABLED,
} from '../../utils/constants';
import axios from 'axios';
import useAuthCheck from '../../hooks/useAuthCheck';

const AdminUserManagementModal = ({ user, setManagedUser, setUsers }) => {
  useAuthCheck();
  const [email, setEmail] = useState(user.email);
  const [name, setName] = useState(user.name);
  const [type, setType] = useState(user.type);
  const [status, setStatus] = useState(user.status);
  const [errors, setErrors] = useState(null);

  const onChangeEmail = (e) => {
    setEmail(e.target.value);
  }

  const onChangeName = (e) => {
    setName(e.target.value);
  }

  const onChangeType = (e) => {
    setType(e.target.value);
  }

  const onChangeStatus = (e) => {
    setStatus(e.target.value);
  }

  const isFieldInvalid = (fieldName) => {
    return errors && errors.map(error => error.key).includes(fieldName);
  }

  const onSubmit = (e) => {
    e.preventDefault();
    const config = {
      url: user.id
        ? `${process.env.REACT_APP_API_BASE_URL}/api/users/${user.id}`
        : `${process.env.REACT_APP_API_BASE_URL}/api/users`,
      method: user.id ? 'PUT' : 'POST',
      data: { email, name, type, status },
    };
    axios.request(config)
      .then(response => {
        if (user.id) {
          setUsers(users => users.map(u => {
            return u.id === user.id ? {
              ...u,
              email,
              name,
              roles: [type],
              status,
            } : u;
          }));
          setManagedUser(null);
        } else {
          const { data } = response;
          setUsers(users => [data, ...users]);
          setManagedUser(null);
        }
      })
      .catch(error => {
        if (error.response) {
          const { response } = error;
          const { data, status } = response;
          console.log(data)
          if (status >= 500) {
            setErrors([{ key: null, message: `A System Error occurred (Status Code ${status})`}]);
          } else {
            if (status === 422) {
              const errorsArray = [];
              Object.keys(data.errors).forEach(key => {
                data.errors[key].forEach(message => {
                  errorsArray.push({ key, message });
                });
              });
              setErrors(errorsArray);
            } else {
              setErrors([{ key: null, message: `You are not logged in (Status Code ${status})`}]);
            }
          }
        } else {
          setErrors([{ key: null, message: 'A System Error occurred'}]);
        }
        console.log(error);
      });
  }

  return (
    <Modal show onHide={() => setManagedUser(null)}>
      <Modal.Header closeButton>
        <Modal.Title>
          {user.id ?
            (
              <>Edit User <i>{user.name}</i></>
            ) :
            'Add a new User'
          }
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={onSubmit}>
          {errors && errors.map(error => (
            <Alert variant={'danger'} key={error.key+error.message}>{error.message}</Alert>
          ))}
          <Form.Group>
            <Form.Label>Type</Form.Label>
            <Form.Select value={type} onChange={onChangeType}>
              {[USER_TYPE_MEMBER, USER_TYPE_ADMIN].map(t => (
                <option key={t} value={t}>{t}</option>
              ))}
            </Form.Select>
          </Form.Group>
          <Form.Group className={'mt-3'}>
            <Form.Label
              className={ isFieldInvalid('name') ? 'text-danger' : '' }
            >
              Name
            </Form.Label>
            <Form.Control
              onChange={onChangeName}
              value={name}
              className={ isFieldInvalid('name') ? 'is-invalid' : '' }
            />
          </Form.Group>
          <Form.Group className={'mt-3'}>
            <Form.Label
              className={ isFieldInvalid('email') ? 'text-danger' : '' }
            >
              Email
            </Form.Label>
            <Form.Control
              type={'email'}
              onChange={onChangeEmail}
              value={email}
              className={ isFieldInvalid('email') ? 'is-invalid' : '' }
            />
          </Form.Group>
          <Form.Group className={'mt-3'}>
            <Form.Label>Status</Form.Label>
            <Form.Select value={status} onChange={onChangeStatus}>
              {[USER_STATUS_ENABLED, USER_STATUS_DISABLED].map(t => (
                <option key={t} value={t}>{t}</option>
              ))}
            </Form.Select>
          </Form.Group>
          <Form.Group className={'float-end mt-4'}>
            <Button onClick={() => setManagedUser(null)} variant={'light'}>Cancel</Button>
            <Button variant={'primary'} className={'ms-3'} type={'submit'}>Submit</Button>
          </Form.Group>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export default AdminUserManagementModal;
