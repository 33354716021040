import { useState } from 'react';
import axios from 'axios';
import {
  Alert,
  Button,
  Modal,
  Tab,
  Tabs,
} from 'react-bootstrap';
import SelectedEventTypeForm from '../SelectedEventTypeForm';
import { PencilFill } from 'react-bootstrap-icons';
import useAuthCheck from '../../hooks/useAuthCheck';


const SelectedEventTypeModal = ({ hideModal, selectedEventType, setEventTypes }) => {
  useAuthCheck();
  const [editMode, setEditMode] = useState(false);
  const [label, setLabel] = useState(selectedEventType.label);
  const [practitionerDescription, setPractitionerDescription] = useState(selectedEventType.practitioner_description);
  const [researcherDescription, setResearcherDescription] = useState(selectedEventType.researcher_description);
  const [earlyChildhoodMathDescription, setEarlyChildhoodMathDescription] = useState(selectedEventType.early_childhood_math_description);
  const [errors, setErrors] = useState(null);

  const cancel = () => {
    setEditMode(false);
    setErrors(null);
    setLabel(selectedEventType.label);
    setPractitionerDescription(selectedEventType.practitioner_description);
    setResearcherDescription(selectedEventType.researcher_description);
    setEarlyChildhoodMathDescription(selectedEventType.early_childhood_math_description);
  }

  const submit = () => {
    const url = `${process.env.REACT_APP_API_BASE_URL}/api/event-types/${selectedEventType.id}`;
    setErrors(null);
    axios.patch(url, {
      label,
      practitioner_description: practitionerDescription,
      researcher_description: researcherDescription,
      early_childhood_math_description: earlyChildhoodMathDescription,
    })
      .then(response => {
        const { data } = response;
        setEventTypes(eventTypes => eventTypes.map(eventType => eventType.id === selectedEventType.id ? data : eventType));
        hideModal();
      })
      .catch(error => {
        if (error.response) {
          const { response } = error;
          const { data, status } = response;
          console.log(data)
          if (status >= 500) {
            setErrors([{ key: null, message: `A System Error occurred (Status Code ${status})`}]);
          } else {
            if (status === 422) {
              const errorsArray = [];
              Object.keys(data.errors).forEach(key => {
                data.errors[key].forEach(message => {
                  errorsArray.push({ key, message });
                });
              });
              setErrors(errorsArray);
            } else {
              setErrors([{ key: null, message: `You are not logged in (Status Code ${status})`}]);
            }
          }
        } else {
          setErrors([{ key: null, message: 'A System Error occurred'}]);
        }
      });
  }

  const isFieldInvalid = (fieldName) => {
    return errors && errors.map(error => error.key).includes(fieldName);
  }

  return (
    <Modal size={'lg'} show={selectedEventType} onHide={hideModal}>
      <Modal.Header closeButton>
        <Modal.Title>{selectedEventType.label}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {errors && errors.map(error => (
          <Alert variant={'danger'} key={error.key+error.message}>{error.message}</Alert>
        ))}
        <Tabs defaultActiveKey={'practitioner'}>
          <Tab eventKey={'practitioner'} title={'Practitioner Description'} className={'p-2 border-start border-end border-bottom'}>
            <SelectedEventTypeForm
              {...{
                description: practitionerDescription,
                setDescription: setPractitionerDescription,
                editMode,
                fieldName: 'practitioner_description',
                isFieldInvalid,
                label,
                setLabel,
             }}
            />
          </Tab>
          <Tab eventKey={'researcher'} title={'Researcher Description'} className={'p-2 border-start border-end border-bottom'}>
            <SelectedEventTypeForm
              {...{
                description: researcherDescription,
                setDescription: setResearcherDescription,
                editMode,
                fieldName: 'researcher_description',
                isFieldInvalid,
                label,
                setLabel,
             }}
            />
          </Tab>
          <Tab eventKey={'early_childhood_math'} title={'Early Childhood Math Description'} className={'p-2 border-start border-end border-bottom'}>
            <SelectedEventTypeForm
              {...{
                description: earlyChildhoodMathDescription,
                setDescription: setEarlyChildhoodMathDescription,
                editMode,
                fieldName: 'early_childhood_math_description',
                isFieldInvalid,
                label,
                setLabel,
             }}
            />
          </Tab>
        </Tabs>
      </Modal.Body>
      <Modal.Footer>
      {editMode ? (
        <>
          <Button variant={'light'} className={'ms-2 mb-1'} onClick={cancel}>
            Cancel
          </Button>
          <Button onClick={submit}>
            Save
          </Button>
        </>
      ) : (
        <Button variant={'warning'} onClick={() => setEditMode(true)}>
          <PencilFill /> Edit
        </Button>
      )}
      </Modal.Footer>
    </Modal>
  );
}

export default SelectedEventTypeModal;
