import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { formatDateTime } from '../../utils/functions';
import { ListGroup, NavDropdown, Row, Col } from 'react-bootstrap';
import { CheckSquareFill, Square } from 'react-bootstrap-icons';

const SessionList = ({ sessions, user }) => {
  const navigate = useNavigate();
  const schools = [...new Set(sessions.map(s => s.school))].toSorted((a, b) => a > b);
  const teachers = [...new Set(sessions.map(s => s.teacher))].toSorted((a, b) => a > b);
  const classes = [...new Set(sessions.map(s => s.class))].toSorted((a, b) => a > b);
  const [sessionList, setSessionList] = useState(sessions);
  const [selectedSchools, setSelectedSchools] = useState([]);
  const [selectedTeachers, setSelectedTeachers] = useState([]);
  const [selectedClasses, setSelectedClasses] = useState([]);

  const navigateToSession = (session) => {
    navigate( user === null ? `/sessions/${session.id}` : `/admin/users/${user.id}/sessions/${session.id}`);
  }

  const toggleSchool = (school) => {
    setSelectedSchools(v => v.includes(school) ? v.filter(s => s !== school) : [...v, school]);
  }

  const isSchoolDisabled = (school) => {
    return !sessions
      .filter(session => selectedTeachers.length === 0 || selectedTeachers.includes(session.teacher))
      .filter(session => selectedClasses.length === 0 || selectedClasses.includes(session.class))
      .map(session => session.school)
      .includes(school);
  }

  const toggleTeacher = (teacher) => {
    setSelectedTeachers(v => v.includes(teacher) ? v.filter(t => t !== teacher) : [...v, teacher]);
  }

  const isTeacherDisabled = (teacher) => {
    return !sessions
      .filter(session => selectedSchools.length === 0 || selectedSchools.includes(session.school))
      .filter(session => selectedClasses.length === 0 || selectedClasses.includes(session.class))
      .map(session => session.teacher)
      .includes(teacher);
  }

  const toggleClass = (c) => {
    setSelectedClasses(v => v.includes(c) ? v.filter(t => t !== c) : [...v, c]);
  }

  const isClassDisabled = (c) => {
    return !sessions
      .filter(session => selectedSchools.length === 0 || selectedSchools.includes(session.school))
      .filter(session => selectedTeachers.length === 0 || selectedTeachers.includes(session.teacher))
      .map(session => session.class)
      .includes(c);
  }

  useEffect(() => {
    setSessionList(
      sessions
        .filter(session => selectedSchools.length === 0 || selectedSchools.includes(session.school))
        .filter(session => selectedTeachers.length === 0 || selectedTeachers.includes(session.teacher))
        .filter(session => selectedClasses.length === 0 || selectedClasses.includes(session.class))
    )
  }, [
    sessions,
    selectedSchools,
    selectedTeachers,
    selectedClasses,
  ]);

  if (!sessions || sessions.length === 0) {
    return user === null ? (
      <>You haven't created any Sessions yet</>
    ) : (
      <>{user.name} has not created any Sessions yet</>
    );
  }

  return (
    <ListGroup>
      <ListGroup.Item>
        <Row className={'hover-pointer fw-bold'}>
          <Col sm={2}>Date</Col>
          <Col>
            {schools.length > 1 ?
              <NavDropdown title={'School'} className={'session-list-filter'}>
                <NavDropdown.Item onClick={() => setSelectedSchools([])}>
                  {selectedSchools.length === 0 ? <CheckSquareFill className={'pe-1'} /> : <Square className={'pe-1'} />}
                  Select All
                </NavDropdown.Item>
                {schools.map(school => (
                  <NavDropdown.Item onClick={() => toggleSchool(school)} disabled={isSchoolDisabled(school)}>
                    {selectedSchools?.includes(school) ? <CheckSquareFill className={'pe-1'} /> : <Square className={'pe-1'} />}
                    { school }
                  </NavDropdown.Item>
                ))}
              </NavDropdown> :
              <>School</>
            }
          </Col>
          <Col>
            {schools.length > 1 ?
              <NavDropdown title={'Teacher'} className={'session-list-filter'}>
                <NavDropdown.Item onClick={() => setSelectedTeachers([])}>
                  {selectedTeachers.length === 0 ? <CheckSquareFill className={'pe-1'} /> : <Square className={'pe-1'} />}
                  Select All
                </NavDropdown.Item>
                {teachers.map(teacher => (
                  <NavDropdown.Item onClick={() => toggleTeacher(teacher)} disabled={isTeacherDisabled(teacher)}>
                    {selectedTeachers?.includes(teacher) ? <CheckSquareFill className={'pe-1'} /> : <Square className={'pe-1'} />}
                    { teacher }
                  </NavDropdown.Item>
                ))}
              </NavDropdown> :
              <>Teacher</>
            }
          </Col>
          <Col>
            {classes.length > 1 ?
              <NavDropdown title={'Class'} className={'session-list-filter'}>
                <NavDropdown.Item onClick={() => setSelectedClasses([])}>
                  {selectedClasses.length === 0 ? <CheckSquareFill className={'pe-1'} /> : <Square className={'pe-1'} />}
                  Select All
                </NavDropdown.Item>
                {classes.map(c => (
                  <NavDropdown.Item onClick={() => toggleClass(c)} disabled={isClassDisabled(c)}>
                    {selectedClasses?.includes(c) ? <CheckSquareFill className={'pe-1'} /> : <Square className={'pe-1'} />}
                    { c }
                  </NavDropdown.Item>
                ))}
              </NavDropdown> :
              <>Class</>
            }
          </Col>
          <Col sm={2}>Duration</Col>
        </Row>
      </ListGroup.Item>
      {sessionList.map(session => (
        <ListGroup.Item key={session.id} className={'hover-pointer session-list-row'} onClick={() => navigateToSession(session)}>
          <Row>
            <Col sm={2}>{formatDateTime(session.started_at)}</Col>
            <Col>{session.school}</Col>
            <Col>{session.teacher}</Col>
            <Col>{session.class}</Col>
            <Col sm={2}>
              { Math.floor(session.duration/60) }m { session.duration % 60 }s
            </Col>
          </Row>
        </ListGroup.Item>
      ))}
    </ListGroup>
  );
}

export default SessionList;
