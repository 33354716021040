import { useState } from 'react';
import { Button } from 'react-bootstrap';
import SessionEvent from '../SessionEvent';
import SessionEventTypeModal from '../SessionEventTypeModal';


const SessionEventTimeline = ({
  eventType,
  session,
}) => {
  const [showSessionEventTypeModal, setShowSessionEventTypeModal] = useState(false);
  const events = session.events.filter(e => e.key === eventType.key);
  const labelStyle = {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '100%',
    marginBottom: '1px',
  };

  return (
    <div className={'w-100 session-chart-row'}>
      <div className={'w-25 d-inline-block'}>
        <Button
          variant={'link'}
          size={'sm'}
          className={'text-decoration-none p-0 ps-2'}
          style={labelStyle}
          onClick={() => setShowSessionEventTypeModal(true)}
        >
          {eventType.label}
        </Button>
      </div>
      <div className={'position-relative w-75 d-inline-block'}>
        {events.map((event, index) => (
          <SessionEvent key={index} {...{ session, event }} />
        ))}
      </div>
      {showSessionEventTypeModal && (
        <SessionEventTypeModal {...{ eventType, setShowSessionEventTypeModal }} />
      )}
    </div>
  );
}

export default SessionEventTimeline;
