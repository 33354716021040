import {
  HABITS_OF_INTERACTION,
  HABITS_OF_MIND,
  CATEGORY_CATALYTIC_TEACHING_ACTIONS_ELICITS,
  CATEGORY_CATALYTIC_TEACHING_ACTIONS_ORIENTS,
  CATEGORY_TEACHING_ROUTINES,
} from '../../utils/constants';
import SessionAnnotationTimeline from '../SessionAnnotationTimeline';
import SessionEventTimeline from '../SessionEventTimeline';
import SessionMetaData from '../SessionMetaData';
import SessionRoutineTimeline from '../SessionRoutineTimeline';
import SessionTimelineScale from '../SessionTimelineScale';
import SessionTimelineScaleLabels from '../SessionTimelineScaleLabels';


const SessionChart = ({ eventTypes, session }) => {
  const sortBySortOrder = (a, b) => {
    return a.sort_order > b.sort_order;
  }

  return (
    <>
      <SessionMetaData {...{ session }} />
      <div className={'position-relative'}>
        {session && eventTypes && (
          <>
            <SessionTimelineScaleLabels {...{ session }} />
            <SessionTimelineScale {...{ session }} />
            <div className={'pt-2 border-bottom session-chart-section text-student'}>
              <h5 className={'ms-2 mb-0'}>Habits of Mind</h5>
              {eventTypes.filter(type => type.category === HABITS_OF_MIND).toSorted(sortBySortOrder).map(eventType => (
                <SessionEventTimeline key={eventType.key} {...{ eventType, session }} />
              ))}
            </div>
            <div className={'pt-2 border-bottom session-chart-section text-student'}>
              <h5 className={'ms-2 mb-0'}>Habits of Interaction</h5>
              {eventTypes.filter(type => type.category === HABITS_OF_INTERACTION).toSorted(sortBySortOrder).map(eventType => (
                <SessionEventTimeline key={eventType.key} {...{ eventType, session }} />
              ))}
            </div>
            <div className={'pt-2 border-bottom session-chart-section text-teacher'}>
              <h5 className={'ms-2 mb-0'}>Teaching Routines</h5>
              {eventTypes.filter(type => type.category === CATEGORY_TEACHING_ROUTINES).toSorted(sortBySortOrder).map(eventType => (
                <SessionRoutineTimeline key={eventType.key} {...{ eventType, session }} />
              ))}
            </div>
            <div className={'pt-2 border-bottom session-chart-section text-teacher'}>
              <h5 className={'ms-2 mb-0'}>Catalytic Teaching Actions</h5>
              {eventTypes.filter(type => type.category === CATEGORY_CATALYTIC_TEACHING_ACTIONS_ELICITS).toSorted(sortBySortOrder).map(eventType => (
                <SessionEventTimeline key={eventType.key} {...{ eventType, session }} />
              ))}
            </div>
            <div className={'pt-2 border-bottom session-chart-section text-teacher'}>
              <h5 className={'ms-2 mb-0'}>Catalytic Teaching Actions</h5>
              {eventTypes.filter(type => type.category === CATEGORY_CATALYTIC_TEACHING_ACTIONS_ORIENTS).toSorted(sortBySortOrder).map(eventType => (
                <SessionEventTimeline key={eventType.key} {...{ eventType, session }} />
              ))}
            </div>
            <div className={'py-2'}>
              <SessionAnnotationTimeline {...{ session }} />
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default SessionChart;
