import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  RouterProvider,
} from 'react-router-dom';

import Root from './components/Root';
import AdminEventTypes from './components/AdminEventTypes';
import AdminUsersIndex from './components/AdminUsersIndex';
import AdminUsersGet from './components/AdminUsersGet';
import AdminUsersSession from './components/AdminUsersSession';
import Dashboard from './components/Dashboard';
import ErrorPage from './components/ErrorPage';
import Logout from './components/Logout';
import Register from './components/Register';
import ResetPassword from './components/ResetPassword';
import Session from './components/Session';
import SplashPage from './components/SplashPage';

import AdminRoute from './components/AdminRoute';
import UserRoute from './components/UserRoute';


const router = createBrowserRouter([
  {
    path: '/',
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: '',
        element: <SplashPage />,
      },
      {
        path: 'dashboard',
        element: (
          <UserRoute>
            <Dashboard />
          </UserRoute>
        ),
      },
      {
        path: 'register/:token',
        element: <Register />,
      },
      {
        path: 'reset-password/:token',
        element: <ResetPassword />,
      },
      {
        path: 'sessions/:sessionId',
        element: (
          <UserRoute>
            <Session />
          </UserRoute>
        ),
      },
      {
        path: 'logout',
        element: (<Logout />),
      },
      {
        path: 'admin/event-types',
        element: (
          <UserRoute>
            <AdminRoute>
              <AdminEventTypes />
            </AdminRoute>
          </UserRoute>
        ),
      },
      {
        path: 'admin/users',
        element: (
          <UserRoute>
            <AdminRoute>
              <AdminUsersIndex />
            </AdminRoute>
          </UserRoute>
        ),
      },
      {
        path: 'admin/users/:userId',
        element: (
          <UserRoute>
            <AdminRoute>
              <AdminUsersGet />
            </AdminRoute>
          </UserRoute>
        ),
      },
      {
        path: 'admin/users/:userId/sessions/:sessionId',
        element: (
          <UserRoute>
            <AdminRoute>
              <AdminUsersSession />
              <Session />
            </AdminRoute>
          </UserRoute>
        ),
      },
    ],
  },
]);

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
