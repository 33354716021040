import { Col, Container, Row } from 'react-bootstrap';
import { formatDateTime } from '../../utils/functions';


const SessionMetaData = ({ session }) => {
  return (
    <Container className='border-bottom'>
      <Row>
        <Col className={'my-2'}>
          {formatDateTime(session.started_at)}
        </Col>
        <Col>
          { Math.floor(session.duration/60) }m { session.duration % 60 }s
        </Col>
      </Row>
      <Row className={'py-2'}>
        <Col>
          <div className={'text-muted'}>School</div>
          {session.school}
        </Col>
        <Col>
          <div className={'text-muted small'}>Teacher</div>
          {session.teacher}
        </Col>
        <Col>
          <div className={'text-muted small'}>Class</div>
          {session.class}
        </Col>
        <Col>
          <div className={'text-muted small'}>Focus</div>
          {session.focus}
        </Col>
      </Row>
    </Container>
  );
}

export default SessionMetaData;
