import { useState } from 'react';
import {
  HABITS_OF_INTERACTION,
  HABITS_OF_MIND,
  CATEGORY_CATALYTIC_TEACHING_ACTIONS_ELICITS,
  CATEGORY_CATALYTIC_TEACHING_ACTIONS_ORIENTS,
} from '../../utils/constants';
import { formatTimeFromSessionStart } from '../../utils/functions';
import { Button } from 'react-bootstrap';
import SessionEventTypeModal from '../SessionEventTypeModal';


const SessionEvent = ({ session, eventType, event }) => {
  const [showSessionEventTypeModal, setShowSessionEventTypeModal] = useState(false);
  const actor = [HABITS_OF_INTERACTION, HABITS_OF_MIND].includes(eventType.category) ? 'Student' : 'Teacher';
  const textClass = actor === 'Student' ? 'text-student' : 'text-teacher';
  let category = '';
  switch (eventType.category) {
    case HABITS_OF_INTERACTION:
      category = 'Habits of Interaction';
      break;
    case HABITS_OF_MIND:
      category = 'Habits of Mind';
      break;
    case CATEGORY_CATALYTIC_TEACHING_ACTIONS_ELICITS:
    case CATEGORY_CATALYTIC_TEACHING_ACTIONS_ORIENTS:
      category = 'Catalytic Teaching Actions';
      break;
    default:
      category = 'Teaching Routines';
      break;
  }

  return (
    <tr>
      <td className={textClass + ' text-end'}>
        {formatTimeFromSessionStart(session, event.started_at)}
        {event.ended_at && (
          <> - {formatTimeFromSessionStart(session, event.ended_at)}</>
        )}
      </td>
      <td className={textClass}>
        {actor}
      </td>
      <td className={textClass}>
        {category}
      </td>
      <td className={textClass}>
        <Button
          variant={'link'}
          className={`${textClass} text-decoration-none p-0`}
          onClick={() => setShowSessionEventTypeModal(true)}
        >
          {eventType.label}
        </Button>
        {showSessionEventTypeModal && (
          <SessionEventTypeModal {...{ eventType, setShowSessionEventTypeModal }} />
        )}
      </td>
    </tr>
  )
}

export default SessionEvent;
