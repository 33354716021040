import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { CHART_ROUTINE_BAR_HEIGHT } from '../../utils/constants';
import {
  getOffsetLeft,
  getSessionWholeMinutes,
  formatTimeFromSessionStart
} from '../../utils/functions';


const SessionRoutine = ({
  session,
  event,
}) => {
  const eventStart = new Date(event.started_at) / 1000;
  const eventEnd = new Date(event.ended_at) / 1000;
  const eventLength = eventEnd - eventStart;
  const percentageWidth = eventLength / (60 * getSessionWholeMinutes(session)) * 100;

  const barStyle = {
    position: 'absolute',
    bottom: '-0.5px',
    height: `${CHART_ROUTINE_BAR_HEIGHT}px`,
    borderRadius: `${CHART_ROUTINE_BAR_HEIGHT/4}px`,
    left: `${getOffsetLeft(session, event)}%`,
    width: `${percentageWidth}%`,
    backgroundColor: '#a00',
  };

  const renderTooltip = (props) => (
    <Tooltip {...props}>
      {formatTimeFromSessionStart(session, event.started_at)} - {formatTimeFromSessionStart(session, event.ended_at)}
    </Tooltip>
  );

  return (
    <OverlayTrigger
      placement={'top'}
      overlay={renderTooltip}
    >
      <div style={barStyle} />
    </OverlayTrigger>
  );
}

export default SessionRoutine;
