import { useState } from 'react';
import { Link, useNavigate, useOutletContext } from 'react-router-dom';
import axios from 'axios';
import { useSearchParams } from 'react-router-dom';
import ForgotPasswordModal from '../ForgotPasswordModal';
import {
  Alert,
  Button,
  Form,
} from 'react-bootstrap';

const LoginForm = () => {
  const [searchParams] = useSearchParams();
  const sessionExpired = searchParams.get('sessionExpired') === 'true';
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false);
  const { setAuth } = useOutletContext();
  const navigate = useNavigate();

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  }

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  }

  const onSubmit = (e) => {
    e.preventDefault();
    const url = `${process.env.REACT_APP_API_BASE_URL}/api/auth/login`;
    setError(null);
    axios.post(url, {
        email,
        password,
      })
      .then(response => {
        const { data } = response;
        const token = data.access_token;
        setAuth({
          token,
          expiresAt: Date.now() + (data.expires_in * 1000),
          user: data.user,
        });
        navigate('dashboard');
      })
      .catch(error => {
        if (error.response && error.response.status === 401) {
          setError('The credentials you provided are not valid');
        } else {
          console.log(error);
          setError('A System Error occurred');
        }
      });
  }

  return (
    <>
      <Form onSubmit={onSubmit}>
        <h3>Login</h3>
        {sessionExpired && !error && (
          <Alert variant={'warning'}>Your session has expired. Please log in to continue.</Alert>
        )}
        {error && (
          <Alert variant={'danger'}>{error}</Alert>
        )}
        <Form.Group className={'mb-4'}>
          <Form.Label>Email Address</Form.Label>
          <Form.Control type={'email'} placeholder={'Email Address'} value={email} onChange={handleEmailChange} />
        </Form.Group>
        <Form.Group className={'mb-4'}>
          <Form.Label>Password</Form.Label>
          <Form.Control type={'password'} placeholder={'Password'} value={password} onChange={handlePasswordChange} />
        </Form.Group>
        <div className={'d-grid gap-2'}>
          <Button type={'submit'}>Submit</Button>
          <Link onClick={() => setShowForgotPasswordModal(true)}>I forgot my password</Link>
        </div>
      </Form>
      {showForgotPasswordModal && (
        <ForgotPasswordModal {...{setShowForgotPasswordModal}} />
      )}
    </>
  );
}

export default LoginForm;
