import { useState } from 'react';
import useAuth from '../../hooks/useAuth';
import AccountModal from '../AccountModal';
import {
  Button,
  Col,
  Container,
  Nav,
  Navbar,
  Row,
} from 'react-bootstrap';
import {
  Link,
  Outlet,
} from 'react-router-dom';

const Root = () => {
  const { auth, setAuth } = useAuth();
  const [showAccountModal, setShowAccountModal] = useState(false);
  const buttonLinkStyle = { top: -1 };

  return (
    <>
      <Navbar bg="light" variant="light">
        <Container>
          <Link className={'navbar-brand'} to={'/'}>
            <img src={'/logo192.png'} width={20} className={'img-fluid mb-1 me-1'} alt={'Math Habits logo'}/>
            Math Habits
          </Link>
          {auth?.user && (
            <Nav>
              <Link className={'nav-link'} to={'/dashboard'}>Dashboard</Link>
              {auth.user.roles.includes('Admin') && (
                <>
                  <Link className={'nav-link'} to={'/admin/event-types'}>Event Types</Link>
                  <Link className={'nav-link'} to={'/admin/users'}>Users</Link>
                </>
              )}
              <Button
                variant={'link'}
                className={'nav-link position-relative'}
                style={buttonLinkStyle}
                onClick={() => setShowAccountModal(true)}
              >
                Account
              </Button>
              <Link className={'nav-link'} to={'/logout'}>Logout</Link>
            </Nav>
          )}
        </Container>
      </Navbar>
      <Container className={'my-4'}>
        <Outlet context={{
          auth,
          setAuth,
        }} />
      </Container>
      {auth?.user && showAccountModal && (
        <AccountModal {...{setShowAccountModal, user: auth.user}} />
      )}
      <div className={'position-absolute bottom-0 border-top bg-light'}>
        <Container className={'py-1'} fluid>
          <Row className={'d-flex justify-content-evenly'}>
            <Col md={6} lg={5} className={'d-flex align-items-center'}>
              <img src={'/nsf.png'} width={75} className={'img-fluid m-2 float-start'} alt={'NSF logo'}/>
              <p className={'small mb-0 lh-1'}>This material is supported in part by the National Science Foundation under Grant #1814114 and #1223074. Any opinions, findings, and conclusions or recommendations expressed in this material are those of the authors and do not necessarily reflect the views of the National Science Foundation.</p>
            </Col>
            <Col md={6} lg={5} className={'d-flex align-items-center'}>
              <p className={'mb-0 text-center'}>
                For more information or to ask questions visit
                <a href={'https://www.teachersdg.org'} target={'_blank'} rel={'noreferrer'}>
                  <img width={200} className={'img-fluid ms-2 mb-4'} src={'/teachers-development-group-logo.png'} alt={'Teachers Development Group logo'} />
                </a>
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Root;
