import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Breadcrumb } from 'react-bootstrap';
import BreadcrumbItem from '../BreadcrumbItem';

const AdminUsersSession = () => {
  const { sessionId, userId } = useParams();
  const [user, setUser] = useState(null);

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/users/${userId}`)
      .then(response => {
        const { data } = response;
        setUser(data);
      })
      .catch(error => {
        console.log(error);
      })
  }, []);

  return (
    <>
      <Breadcrumb>
        <BreadcrumbItem to={'/admin/users'}>Users</BreadcrumbItem>
        <BreadcrumbItem to={`/admin/users/${userId}`}>{user?.name}</BreadcrumbItem>
        <BreadcrumbItem active>Session {sessionId}</BreadcrumbItem>
      </Breadcrumb>
    </>
  );
}

export default AdminUsersSession;
