import { useState } from 'react';
import { formatTimeFromSessionStart } from '../../utils/functions';
import { Button } from 'react-bootstrap';
import SessionAnnotationModal from '../SessionAnnotationModal';
import { CardImage, ChatLeftTextFill } from 'react-bootstrap-icons';


const SessionTableAnnotation = ({ annotation, session }) => {
  const [showSessionAnnotationModal, setShowSessionAnnotationModal] = useState(false);

  return (
    <tr>
      <td className={'text-end'}>
        {formatTimeFromSessionStart(session, annotation.started_at)}
      </td>
      <td></td>
      <td>
        <Button
          variant={'link'}
          className={'text-decoration-none p-0'}
          onClick={setShowSessionAnnotationModal}
        >
          {annotation.filename
            ? annotation.notes ? <><CardImage/><ChatLeftTextFill className={'mt-1 ms-1'} /></> : <CardImage />
            : <ChatLeftTextFill className={'mt-1'} />
          }
        </Button>
        {showSessionAnnotationModal && (
          <SessionAnnotationModal {...{annotation, session, setShowSessionAnnotationModal}} />
        )}
      </td>
      <td></td>
    </tr>
  )
}

export default SessionTableAnnotation;
