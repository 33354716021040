import { Navigate, useOutletContext } from 'react-router-dom';

const Logout = () => {
  const { setAuth } = useOutletContext();

  setAuth(null);

  return (<Navigate to='/' />);
}

export default Logout;
