import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Alert, Tab, Tabs } from 'react-bootstrap';
import SessionChart from '../SessionChart';
import SessionTable from '../SessionTable';

const SessionTabContentBorder = ({ children }) => (
  <div className={'border-start border-bottom border-end rounded-bottom'}>
    {children}
  </div>
)

const Session = () => {
  const { sessionId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [session, setSession] = useState(null);
  const [eventTypes, setEventTypes] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const sessionUrl = `${process.env.REACT_APP_API_BASE_URL}/api/sessions/${sessionId}`
    axios.get(sessionUrl)
      .then(response => {
        setSession(response.data);
        setIsLoading(false);
      })
      .catch(error => {
        if (!error.response || error.response.status === 500) {
          setError('A System Error occurred');
        } else {
          setError('The Session could not be found')
        }
        setIsLoading(false);
        console.log(error);
      });

    const eventTypesUrl = `${process.env.REACT_APP_API_BASE_URL}/api/event-types`
    axios.get(eventTypesUrl)
      .then(response => {
        setEventTypes(response.data);
      })
      .catch(error => {
        if (!error.response || error.response.status === 500) {
          setError('A System Error occurred');
        }
        console.log(error);
      });
  }, []);

  if (isLoading) {
    return (
      <>Loading...</>
    );
  }

  if (error) {
    return (
      <Alert variant={'danger'}>{error}</Alert>
    )
  }

  return (
    <Tabs defaultActiveKey={'chart'} fill>
      <Tab eventKey={'chart'} title={'Chart'}>
        <SessionTabContentBorder>
          <SessionChart {...{ eventTypes, session }}/>
        </SessionTabContentBorder>
      </Tab>
      <Tab eventKey={'table'} title={'Table'}>
        <SessionTabContentBorder>
          <SessionTable {...{ eventTypes, session }} />
        </SessionTabContentBorder>
      </Tab>
    </Tabs>
  );
}

export default Session;
