import { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import ChangePasswordForm from '../ChangePasswordForm';

const AccountModal = ({ setShowAccountModal, user }) => {
  const [showChangePasswordForm, setShowChangePasswordForm] = useState(false);

  return (
    <Modal show onHide={() => setShowAccountModal(false)}>
      <Modal.Header closeButton>
        <Modal.Title>{showChangePasswordForm ? 'Change your password' : 'Your Account'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {showChangePasswordForm ? (
          <ChangePasswordForm {...{setShowChangePasswordForm}} />
        ) : (
          <>
            <div>
              Name: {user.name}
            </div>
            <div className={'mt-3'}>
              Email: {user.email}
            </div>
            <div className={'my-2'}>
              <Button variant={'link'} className={'p-0'} onClick={() => setShowChangePasswordForm(true)}>Change Password</Button>
            </div>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
}

export default AccountModal;
