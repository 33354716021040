import { Navigate, useOutletContext } from 'react-router-dom';

const AdminRoute = ({ children }) => {
  const { auth } = useOutletContext();

  if (!auth?.user.roles.includes('Admin')) {
    return <Navigate to={'/dashboard'} />
  }

  return children;
}

export default AdminRoute;
