import { useOutletContext } from 'react-router-dom';
import {
  Col,
  Row,
} from 'react-bootstrap';
import LoginForm from '../LoginForm';

const SplashPage = () => {
  const { auth } = useOutletContext();

  return (
    <Row>
      <Col sm={8} className={'container'}>
        <Row className={'d-flex justify-content-center'}>
          <Col lg={8}>
            <img src={'/math-habits-screenshot.png'} className={'img-fluid pb-3 pt-1'} alt={'Math Habits app screenshot'} />
          </Col>
        </Row>
        <Row>
          <Col>
            <p className={'m-0'}>
              <em>Math Habits</em> is a classroom observation tool intended for math education practitioners to assess the presence of students&rsquo; habits of mind and habits of interaction as well as teachers&rsquo; routines and actions in real-time. Data generated by the tool and represented on this platform allow users to analyze relationships between teachers&rsquo; routines and actions and students&rsquo; habits of mind and interaction. These data offer practitioners a means to offer feedback on teachers&rsquo; and students&rsquo; strengths as well as areas for growth.
            </p>
          </Col>
        </Row>
      </Col>
      <Col sm={4}>
        {!auth?.user && (
          <LoginForm />
        )}
      </Col>
    </Row>
  );
}

export default SplashPage;
