export const HABITS_OF_MIND = 'HABITS_OF_MIND';
export const HABITS_OF_INTERACTION = 'HABITS_OF_INTERACTION';

export const CATEGORY_TEACHING_ROUTINES = 'TEACHING_ROUTINES';
export const CATEGORY_CATALYTIC_TEACHING_ACTIONS_ELICITS = 'CATALYTIC_TEACHING_ACTIONS_ELICITS';
export const CATEGORY_CATALYTIC_TEACHING_ACTIONS_ORIENTS = 'CATALYTIC_TEACHING_ACTIONS_ORIENTS';

export const USER_TYPE_ADMIN = 'Admin';
export const USER_TYPE_MEMBER = 'Member';

export const USER_STATUS_ENABLED = 'Enabled';
export const USER_STATUS_DISABLED = 'Disabled';

export const CHART_EVENT_ICON_SIZE = 12;
export const CHART_ROUTINE_BAR_HEIGHT = 12;
