import { Modal } from 'react-bootstrap';


const SessionEventTypeModal = ({ eventType, setShowSessionEventTypeModal }) => {
  return (
    <Modal show onHide={() => setShowSessionEventTypeModal(false)}>
      <Modal.Header closeButton>
        <Modal.Title>{eventType.label}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div dangerouslySetInnerHTML={{ __html: eventType.practitioner_description }} />
      </Modal.Body>
    </Modal>
  );
}

export default SessionEventTypeModal;
