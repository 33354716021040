import { useState } from 'react';
import axios from 'axios';

export default function useAuth() {
  const setBearerToken = (token) => {
    axios.defaults.headers.common = {
      'Accept': 'application/json',
      'Authorization': `bearer ${token}`,
    };
  }

  const getAuth = () => {
    let v = JSON.parse(localStorage.getItem('auth'));
    if (!v) {
      return null;
    } else {
      if (v.expiresAt <= Date.now()) {
        localStorage.removeItem('auth');

        return null;
      }

      setBearerToken(v.token);

      return v;
    }
  };

  const [auth, setAuth] = useState(getAuth());

  const saveAuth = (v) => {
    if (v === null) {
      localStorage.removeItem('auth');
      setAuth(null);
    } else {
      localStorage.setItem('auth', JSON.stringify(v));
      setBearerToken(v.token);
      setAuth(v);
    }
  };

  return {
    auth,
    setAuth: saveAuth,
  }
}
