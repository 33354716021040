import { useEffect, useState } from 'react';
import { Container, Table } from 'react-bootstrap';
import SessionMetaData from '../SessionMetaData';
import SessionTableAnnotation from '../SessionTableAnnotation';
import SessionTableEvent from '../SessionTableEvent';


const SessionTable = ({ eventTypes, session }) => {
  const [eventsWithAnnotations, setEventsWithAnnotations] = useState([]);

  const sortByStartedAt = (a, b) => {
    return new Date(a.started_at) - new Date(b.started_at);
  }

  useEffect(() => {
    if (session && (session.events.length || session.annotations.length)) {
      setEventsWithAnnotations([...session.events, ...session.annotations].sort(sortByStartedAt));
    } else {
      setEventsWithAnnotations([]);
    }
  }, [session]);

  return (
    <>
      <SessionMetaData {...{ session }} />
      <Container className={'p-0'}>
        <Table>
          <tbody>
            {eventTypes && eventsWithAnnotations.map((event, index) => {
              if (event.key) {
                return (
                  <SessionTableEvent
                    key={index}
                    session={session}
                    event={event}
                    eventType={eventTypes.find(e => e.key === event.key)}
                  />
                )
              } else {
                return (
                  <SessionTableAnnotation key={index} annotation={event} session={session} />
                );
              }
            })}
          </tbody>
        </Table>
      </Container>
    </>
  );
}

export default SessionTable;
