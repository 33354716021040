import { Button } from 'react-bootstrap';

const EventTypeListItem = ({ eventType, setSelectedEventType }) => {
  return (
    <Button
      variant={'outline-primary'}
      className={'text-start'}
      onClick={() => setSelectedEventType(eventType)}
    >
      {eventType.label}
    </Button>
  );
}

export default EventTypeListItem;
