import { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import { Alert, Button, Form } from 'react-bootstrap';

const ResetPassword = () => {
  const passwordConfirmationErrorMessage = 'The password field confirmation does not match.';
  const { token } = useParams();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [errors, setErrors] = useState(null);
  const [success, setSuccess] = useState(false);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  }

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  }

  const handlePasswordConfirmationChange = (e) => {
    setPasswordConfirmation(e.target.value);
  }

  const onSubmit = (e) => {
    e.preventDefault();
    const url = `${process.env.REACT_APP_API_BASE_URL}/api/password-resets/${token}`;
    setErrors(null);
    axios.put(url, {
        email,
        password,
        password_confirmation: passwordConfirmation,
      })
      .then(response => {
        setSuccess(true);
      })
      .catch(error => {
        if (error.response) {
          const { response } = error;
          const { data, status } = response;
          if (status === 422) {
            const errorsArray = [];
            Object.keys(data.errors).forEach(key => {
              data.errors[key].forEach(message => {
                errorsArray.push({ key, message });
              });
            });
            setErrors(errorsArray);
          } else if (status === 429) {
            setErrors([{message: 'Please wait before retrying'}]);
          } else {
            setErrors([{message: 'A System Error occurred'}]);
          }
        } else {
          console.log(error);
          setErrors([{message: 'A System Error occurred'}]);
        }
      });
  }

  const isFieldInvalid = (fieldName) => {
    if (fieldName === 'passwordConfirmation') {
      return errors && errors.some(e => e.key === 'password' && e.message === passwordConfirmationErrorMessage);
    }

    return errors && errors.map(error => error.key).includes(fieldName);
  }

  if (success) {
    return (
      <Alert variant={'success'}>
        Your new password has been set!
        {' '}
        <Link to={'/'}>Login to continue</Link>
      </Alert>
    );
  }

  return (
    <Form onSubmit={onSubmit}>
      <h3>Reset your Password</h3>
      {errors && errors.map(error => (
        <Alert variant={'danger'}>{error.message}</Alert>
      ))}
      <Form.Group className={'mb-4'}>
        <Form.Label>Email Address</Form.Label>
        <Form.Control
          type={'email'}
          placeholder={'Email Address'}
          value={email}
          onChange={handleEmailChange}
          className={ isFieldInvalid('email') ? 'is-invalid' : '' }
        />
      </Form.Group>
      <Form.Group className={'mb-4'}>
        <Form.Label>Password</Form.Label>
        <Form.Control
          type={'password'}
          placeholder={'Password'}
          value={password}
          onChange={handlePasswordChange}
          className={ isFieldInvalid('password') ? 'is-invalid' : '' }
        />
      </Form.Group>
      <Form.Group className={'mb-4'}>
        <Form.Label>Confirm Password</Form.Label>
        <Form.Control
          type={'password'}
          placeholder={'Confirm Password'}
          value={passwordConfirmation}
          onChange={handlePasswordConfirmationChange}
          className={ isFieldInvalid('passwordConfirmation') ? 'is-invalid' : '' }
        />
      </Form.Group>
      <div className={'d-grid gap-2'}>
        <Button type={'submit'}>Submit</Button>
      </div>
    </Form>
  );
}

export default ResetPassword;
