import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { CircleFill } from 'react-bootstrap-icons';
import { CHART_EVENT_ICON_SIZE } from '../../utils/constants';
import {
  getOffsetLeft,
  formatTimeFromSessionStart
} from '../../utils/functions';


const SessionEvent = ({
  session,
  event,
}) => {
  const iconStyle = {
    position: 'absolute',
    bottom: '-0.5px',
    left: `calc(${getOffsetLeft(session, event)}% - ${CHART_EVENT_ICON_SIZE/2}px)`,
  };

  const renderTooltip = (props) => (
    <Tooltip {...props}>
      {formatTimeFromSessionStart(session, event.started_at)}
    </Tooltip>
  );

  return (
    <OverlayTrigger
      placement={'top'}
      overlay={renderTooltip}
    >
      <CircleFill
        style={iconStyle}
        size={CHART_EVENT_ICON_SIZE}
      />
    </OverlayTrigger>
  );
}

export default SessionEvent;
