import { useEffect, useState } from 'react';
import { Navigate, useOutletContext, useParams } from 'react-router-dom';
import axios from 'axios';
import { Breadcrumb } from 'react-bootstrap';
import BreadcrumbItem from '../BreadcrumbItem';
import SessionList from '../SessionList';

const AdminUsersGet = () => {
  const { auth } = useOutletContext();
  const { userId } = useParams();
  const [user, setUser] = useState(null);

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/users/${userId}`)
      .then(response => {
        const { data } = response;
        setUser(data);
      })
      .catch(error => {
        console.log(error);
      })
  }, [userId]);

  if (auth.user.id === parseInt(userId)) {
    return <Navigate to={'/admin/users'} />
  }

  return (
    <>
      <Breadcrumb>
        <BreadcrumbItem to={'/admin/users'}>Users</BreadcrumbItem>
        <BreadcrumbItem active>{user?.name}</BreadcrumbItem>
      </Breadcrumb>
      <h1>{user?.name}</h1>
      <h2>Sessions:</h2>
      {user && (
        <SessionList {...{ sessions: user.sessions, user }} />
      )}
    </>
  );
}

export default AdminUsersGet;
