import { useEffect, useState } from 'react';
import axios from 'axios';
import {
  USER_TYPE_ADMIN,
  USER_TYPE_MEMBER,
  USER_STATUS_ENABLED,
  USER_STATUS_DISABLED,
} from '../../utils/constants';
import { Breadcrumb, Button, ListGroup } from 'react-bootstrap';
import AdminUserManagementModal from '../AdminUserManagementModal';
import AdminUsersIndexHeaderRow from '../AdminUsersIndexHeaderRow';
import AdminUsersIndexUserRow from '../AdminUsersIndexUserRow';

const AdminUsersIndex = () => {
  const [users, setUsers] = useState(null);
  const [admins, setAdmins] = useState([]);
  const [members, setMembers] = useState([]);
  const [managedUser, setManagedUser] = useState(null);
  const [emailDomains, setEmailDomains] = useState([]);
  const [selectedEmailDomain, setSelectedEmailDomain] = useState(null);
  const [membersList, setMembersList] = useState([]);

  const emptyUser = {
    name: '',
    email: '',
    type: USER_TYPE_MEMBER,
    status: USER_STATUS_ENABLED,
  };

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/users`)
      .then(response => {
        const { data } = response;
        setUsers(data);
      })
      .catch(error => {
        console.log(error);
      })
  }, []);

  useEffect(() => {
    if (users) {
      setAdmins([
        ...users.filter(user => (
          user.roles.includes(USER_TYPE_ADMIN) && user.status === USER_STATUS_ENABLED
        )).toSorted((a, b) => a.name > b.name),
        ...users.filter(user => (
          user.roles.includes(USER_TYPE_ADMIN) && user.status === USER_STATUS_DISABLED
        )).toSorted((a, b) => a.name > b.name),
      ]);
      setMembers([
        ...users.filter(user => (
          user.roles.includes(USER_TYPE_MEMBER) && user.status === USER_STATUS_ENABLED
        )).toSorted((a, b) => a.name > b.name),
        ...users.filter(user => (
          user.roles.includes(USER_TYPE_MEMBER) && user.status === USER_STATUS_DISABLED
        )).toSorted((a, b) => a.name > b.name),
      ]);
      setEmailDomains(
        [...new Set([
          ...users
            .filter(user => user.roles.includes(USER_TYPE_MEMBER))
            .map(u => u.email.split('@').pop().toLowerCase())
        ].toSorted((a, b) => a > b))]
      );
    } else {
      setAdmins([]);
      setMembers([]);
    }
  }, [users]);

  useEffect(() => {
    setMembersList(
      members.filter(member => {
        return selectedEmailDomain ? member.email.split('@').pop().toLowerCase() === selectedEmailDomain : true;
      })
    );
  }, [
    selectedEmailDomain,
    members,
  ]);

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item active>Users</Breadcrumb.Item>
      </Breadcrumb>
      <h1>
        Users
        <Button
          variant={'link'}
          className={'text-success fw-bold fs-3 p-0 mb-2 ms-2 text-decoration-none'}
          onClick={() => setManagedUser(emptyUser)}
        >
          +
        </Button>
      </h1>
      <h2>Admins</h2>
      <ListGroup>
        <AdminUsersIndexHeaderRow />
        {admins.map(user => (
          <AdminUsersIndexUserRow key={user.id} {...{user, setManagedUser}} />
        ))}
      </ListGroup>
      <h2 className={'mt-4'}>Members</h2>
      <ListGroup>
        <AdminUsersIndexHeaderRow {...{ emailDomains, selectedEmailDomain, setSelectedEmailDomain }} />
        {membersList.map(user => (
          <AdminUsersIndexUserRow key={user.id} {...{user, setManagedUser}} />
        ))}
      </ListGroup>
      {managedUser && (
        <AdminUserManagementModal {...{
          user: managedUser,
          setManagedUser,
          setUsers
        }} />
      )}
    </>
  );
}

export default AdminUsersIndex;
