import { useOutletContext } from 'react-router-dom';

export default function useAuthCheck() {
  const { auth, setAuth } = useOutletContext();

  if (!auth || Date.now() >= auth?.expiresAt) {
    setAuth(null);
  }

  return null;
}
