import { useEffect, useState } from 'react';
import axios from 'axios';
import {
  HABITS_OF_INTERACTION,
  HABITS_OF_MIND,
  CATEGORY_CATALYTIC_TEACHING_ACTIONS_ELICITS,
  CATEGORY_CATALYTIC_TEACHING_ACTIONS_ORIENTS,
  CATEGORY_TEACHING_ROUTINES,
} from '../../utils/constants';
import {
  Alert,
  Col,
  Container,
  ButtonGroup,
  Row,
} from 'react-bootstrap';
import EventTypeListItem from '../EventTypeListItem';
import SelectedEventTypeModal from '../SelectedEventTypeModal';

axios.defaults.withCredentials = true;

const AdminEventTypes = () => {
  const [error, setError] = useState(null);
  const [eventTypes, setEventTypes] = useState(null);
  const [selectedEventType, setSelectedEventType] = useState(null);

  const hideModal = () => {
    setSelectedEventType(null);
  }

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/api/event-types`)
      .then(r => {
        setEventTypes(r.data);
        setError(null);
      })
      .catch(error => {
        if (error.response) {
          setError(`A System Error occurred (Status Code ${error.response.status})`);
        } else {
          setError('A System Error occurred');
        }
      });
  }, []);

  return (
    <Container>
      <h1>Math Habits Manager</h1>
      {error && (
        <Alert variant={'danger'} className={'mt-3'}>{error}</Alert>
      )}
      <Row className={'py-2'}>
        <h3>Student Events</h3>
        <Col>
          <h5>Habits of Mind</h5>
          <ButtonGroup vertical className={'d-block'}>
            {eventTypes && eventTypes.filter(e => e.category === HABITS_OF_MIND).map(eventType => (
              <EventTypeListItem key={eventType.id} {...{ eventType, setSelectedEventType }} />
            ))}
          </ButtonGroup>
        </Col>

        <Col>
          <h5>Habits of Interaction</h5>
          <ButtonGroup vertical className={'d-block'}>
            {eventTypes && eventTypes.filter(e => e.category === HABITS_OF_INTERACTION).map(eventType => (
              <EventTypeListItem key={eventType.id} {...{ eventType, setSelectedEventType }} />
            ))}
          </ButtonGroup>
        </Col>
      </Row>

      <h3 className={'pt-4'}>Teacher Events</h3>
      <Row className={'py-2'}>
        <Col>
          <h5>Teaching Routines</h5>
          <ButtonGroup vertical className={'d-block'}>
            {eventTypes && eventTypes.filter(e => e.category === CATEGORY_TEACHING_ROUTINES).map(eventType => (
              <EventTypeListItem key={eventType.id} {...{ eventType, setSelectedEventType }} />
            ))}
          </ButtonGroup>
        </Col>
      </Row>
      <Row className={'py-2'}>
        <Col>
          <h5>Elicits</h5>
          <ButtonGroup vertical className={'d-block'}>
            {eventTypes && eventTypes.filter(e => e.category === CATEGORY_CATALYTIC_TEACHING_ACTIONS_ELICITS).map(eventType => (
              <EventTypeListItem key={eventType.id} {...{ eventType, setSelectedEventType }} />
            ))}
          </ButtonGroup>
        </Col>
        <Col>
          <h5>Orients</h5>
          <ButtonGroup vertical className={'d-block'}>
            {eventTypes && eventTypes.filter(e => e.category === CATEGORY_CATALYTIC_TEACHING_ACTIONS_ORIENTS).map(eventType => (
              <EventTypeListItem key={eventType.id} {...{ eventType, setSelectedEventType }} />
            ))}
          </ButtonGroup>
        </Col>
      </Row>
      {selectedEventType && (
        <SelectedEventTypeModal {...{ hideModal, selectedEventType, setEventTypes }} />
      )}
    </Container>
  );
}

export default AdminEventTypes;
