import SessionAnnotation from '../SessionAnnotation';


const SessionAnnotationTimeline = ({ session }) => {
  const { annotations } = session;

  return (
    <div>
      <div className={'w-25 d-inline-block'}>
        <h5 className={'mb-0 ms-2'}>Notes & Images</h5>
      </div>
      <div className={'position-relative w-75 d-inline-block'}>
        {annotations.map((annotation, index) => (
          <SessionAnnotation key={index} {...{ session, annotation }} />
        ))}
      </div>
    </div>
  );
}

export default SessionAnnotationTimeline;
