export const formatDateTime = (dateTimeString) =>
{
  return new Date(dateTimeString)
    .toLocaleString(
      [],
      {year: '2-digit', month: 'numeric', day: 'numeric', hour: '2-digit', minute:'2-digit'}
    );
}

export const formatDate = (datetime) => {
  const d = new Date(datetime);

  return `${d.getMonth()+1}/${d.getDate()}/${d.getFullYear()}`;
}

export const formatTime = (datetime) => {
  const d = new Date(datetime);
  let minutes = d.getMinutes();
  minutes = minutes < 10 ? `0${minutes}` : minutes;

  return `${d.getHours()}:${minutes}`;
}

export const trimLeadingZeros = (timeString) => (
  timeString.replace(/^0+/, '')
);

export const getSessionWholeMinutes = (session) => {
  return Math.ceil(session.duration/60);
}

export const getOffsetLeft = (session, event) => {
  return ((new Date(event.started_at) - new Date(session.started_at))/1000) / (60 * getSessionWholeMinutes(session)) * 100;
}

export const formatTimeFromSessionStart = (session, eventDateTime) => {
  const difference = (new Date(eventDateTime) - new Date(session.started_at))/1000;
  const minutes = Math.floor(difference/60);
  const seconds = difference % 60;

  return `${minutes}m ${seconds}s`;
}
