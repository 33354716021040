import { Link } from 'react-router-dom';

const BreadcrumbItem = ({ active, children, to }) => {
  return (
    <li className={active ? 'breadcrumb-item active' : 'breadcrumb-item'}>
      {to ? (
        <Link to={to}>
          {children}
        </Link>
      ) : (
        <>
          {children}
        </>
      )}
    </li>
  );
}

export default BreadcrumbItem;
